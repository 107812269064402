import React from "react";
import { MainCarouselData } from "./MainCarouselData.js";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./MainCarousel.css"; // Assuming you move the styles here

const MainCarousel = () => {
  const items = MainCarouselData.map((item, index) => (
    <div key={index} className="relative lg:h-full sm:h-[50rem] w-full">
      <img
        className="cursor-pointer object-center rounded-3xl mx-7 my-10 lg:h-[95vh] lg:w-[95vw] sm:h-[30rem] shadow-2xl shadow-orange-950"
        src={item.Image}
        alt={item.Description || "Jewelry item"}
      />
    </div>
  ));

  return (
    <div className="relative w-full max-w-full overflow-hidden">
      <AliceCarousel
        autoPlay
        infinite
        animationType="fadeout"
        autoPlayInterval={1000}
        animationDuration={800}
        disableButtonsControls
        items={items}
        responsive={{
          0: { items: 1 },
          1024: { items: 1 },
        }}
      />
    </div>
  );
};

export default MainCarousel;
