import React from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Link } from "@mui/material";

const Footer = () => {
  const socialLinks = [
    {
      icon: <InstagramIcon />,
      url: "https://www.instagram.com/moonmadeofficial?igsh=MWQ1cndhbDNtM3kyNQ==",
    },
    {
      icon: <FacebookIcon />,
      url: "https://www.facebook.com/profile.php?id=100090975503111&mibextid=ZbWKwL",
    },
    {
      icon: <YouTubeIcon />,
      url: "https://youtube.com/@Moonmadeofficial?si=BpcQjxOau1Gxr0kW",
    },
    {
      icon: <LinkedInIcon />,
      url: "https://www.linkedin.com/company/moon-made/",
    },
  ];

  return (
    <Box
      sx={{
        mt: { xs: "10px", lg: "20px" },
        borderTop: 1,
        borderColor: "rgba(79, 51, 44, 0.15)",
        pt: 2,
        bgcolor: "#cec8c1",
        color: "#482c2c",
      }}
    >
      <Grid container spacing={2} sx={{ px: { xs: 2, sm: 4, md: 6 }, py: 1 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight="bold"
            fontFamily="cinzel"
          >
            CUSTOMER CARE
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Mob: +91 9582919857
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            +91 8178314273
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Email: themoonmade@gmail.com
          </Typography>
          <Typography variant="subtitle1">
            (Mon to Sat 10 AM to 6 PM)
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight="bold"
            fontFamily="cinzel"
          >
            SHOP OUR PRODUCTS
          </Typography>
          {["SHOP", "GIFTS", "BEST SELLER", "MEN", "WOMEN"].map(
            (item, index) => (
              <Button
                key={index}
                sx={{ pb: 2, display: "block", color: "black" }}
              >
                {item}
              </Button>
            )
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight="bold"
            fontFamily="cinzel"
          >
            USEFUL LINKS
          </Typography>
          {[
            "SEARCH",
            "TRACK YOUR ORDER",
            "About Us",
            "Terms of Service",
            "Refund Policy",
          ].map((item, index) => (
            <Button
              key={index}
              sx={{ pb: 2, display: "block", color: "black" }}
            >
              {item}
            </Button>
          ))}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight="bold"
            fontFamily="cinzel"
          >
            OUR MISSION
          </Typography>
          <Typography variant="subtitle1">
            We at MoonMade focus solely on presenting our customers with the
            most authentic and intricate designs for routine and special
            occasions, at very affordable prices with assured quality.
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "flex-end",
          pr: { xs: 2, sm: 4, lg: 8 },
        }}
      >
        <Typography
          variant="h6"
          fontFamily="cinzel"
          fontWeight="semibold"
          sx={{ textDecoration: "underline" }}
        >
          Follow Us On
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: { xs: 2, sm: 4 },
        }}
      >
        {socialLinks.map((link, index) => (
          <Box key={index} sx={{ mx: 2 }}>
            <Link
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "black", "& svg": { fontSize: "40px" } }}
            >
              {link.icon}
            </Link>
          </Box>
        ))}
      </Box>

      <Grid item xs={12} sx={{ bgcolor: "#b5a491", py: 2 }}>
        <Typography
          align="center"
          variant="subtitle1"
          fontWeight="bold"
          sx={{ color: "#482c2c" }}
        >
          &copy; 2024 MoonMade. All Rights Reserved
        </Typography>
      </Grid>
    </Box>
  );
};

export default Footer;
