import React, { useEffect } from "react";
import AddressCard from "../AddressCard/AddressCard";
import CartItemsCheckout from "../Cart/CartItemsCheckout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getOrderById } from "../../../State/Order/Action";
import { Button, Box, Typography, Divider, Paper } from "@mui/material";
import { createPayment } from "../../../State/Payment/Action";

const OrderSummary = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { order } = useSelector((store) => store);
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("order_id");

  useEffect(() => {
    dispatch(getOrderById(orderId));
  }, [orderId]);

  const handleCreatePayment = () => {
    dispatch(createPayment(orderId));
  };

  return (
    <Box sx={{ padding: "1rem", bgcolor: "#f8f9fa" }}>
      {/* Delivery Address Section */}
      <Paper
        elevation={5}
        sx={{
          marginBottom: "1rem",
          padding: "1rem",
          backgroundColor: "#ffffff",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "black",
            color: "#ffffff",
            padding: "0.5rem 1rem",
            borderRadius: "4px 4px 0 0",
          }}
        >
          DELIVERY ADDRESS
        </Typography>
        <AddressCard address={order.order?.shippingAddress} />
      </Paper>

      {/* Order Items and Price Details Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: "1rem",
        }}
      >
        {/* Order Items */}
        <Box sx={{ flex: 2 }}>
          {order.order.orderItems.map((item, index) => (
            <CartItemsCheckout key={index} item={item} />
          ))}
        </Box>

        {/* Price Details */}
        <Box
          sx={{
            flex: 1,
            position: "sticky",
            top: "1rem",
            alignSelf: "start",
            width: "100%",
          }}
        >
          <Paper
            elevation={3}
            sx={{ padding: "1rem", backgroundColor: "#ffffff" }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: "center", marginBottom: "0.5rem" }}
            >
              Price Details
            </Typography>
            <Divider sx={{ marginBottom: "1rem" }} />
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography
                variant="body1"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <span>Price</span>
                <span>₹{order.order?.totalPrice}</span>
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#388e3c",
                }}
              >
                <span>Delivery Charges:</span>
                <span>
                  <span
                    style={{
                      textDecoration: "line-through",
                      marginRight: "0.5rem",
                      color: "#757575",
                    }}
                  >
                    ₹50
                  </span>
                  FREE
                </span>
              </Typography>
              <Divider sx={{ margin: "1rem 0" }} />
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "bold",
                }}
              >
                <span>Total Payable</span>
                <span>₹{order.order?.totalDiscountedPrice}</span>
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                color: "#388e3c",
                marginBottom: "1rem",
              }}
            >
              Your Total Savings on this order ₹{order.order?.discount}
            </Typography>
            <Button
              onClick={handleCreatePayment}
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#f57c00",
                ":hover": { backgroundColor: "#ff9800" },
                fontWeight: "bold",
                padding: "0.75rem 1rem",
                fontSize: "1rem",
              }}
            >
              Place Your Order
            </Button>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderSummary;
