import {
  Box,
  Button,
  Grid,
  TextField,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import AddressCard from "../AddressCard/AddressCard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createOrder } from "../../../State/Order/Action";
import {
  updateAddress,
  deleteAddress,
  fetchAddresses,
} from "../../../State/Address/Action";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const DeliveryAddressForm = () => {
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressForm, setAddressForm] = useState({});
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editAddressId, setEditAddressId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((store) => store);

  useEffect(() => {
    dispatch(fetchAddresses());
  }, [dispatch]);

  // const handleAddressSelect = (address) => {
  //   setSelectedAddress(address);
  //   setIsEditing(false);
  //   setAddressForm({});
  // };

  const handleDeliverHere = (address) => {
    if (address) {
      const orderData = { address, navigate };
      dispatch(createOrder(orderData));
    }
  };

  const handleEditAddress = (address) => {
    setIsEditing(true);
    setEditAddressId(address._id);
    setAddressForm({
      firstName: address.firstName,
      lastName: address.lastName,
      phoneNumber: address.phoneNumber,
      streetAddress: address.streetAddress,
      city: address.city,
      state: address.state,
      pincode: address.pincode,
      landmark: address.landmark || "",
      email: address.email || "",
    });
  };

  const handleDeleteAddress = (addressId) => {
    dispatch(deleteAddress(addressId));
    setSnackbarMessage("Address deleted successfully!");
    setShowSnackbar(true);
  };

  const handleFormChange = (e) => {
    setAddressForm({ ...addressForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      dispatch(updateAddress(editAddressId, addressForm));
      setSnackbarMessage("Address updated successfully!");
    } else {
      // Save the new address
      dispatch(createOrder({ address: addressForm, navigate }));
      setSnackbarMessage("New address added and order created!");
    }
    setShowSnackbar(true);
    setIsEditing(false);
    setAddressForm({});
    setSelectedAddress(null);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          className="border border-opacity-30 border-[#4f332c] rounded-xl shadow-lg h-[30.5rem] overflow-y-auto bg-[#F5F5F5]"
        >
          <div className="p-5 py-7 border border-opacity-10 border-[#4f332c] shadow-lg">
            {auth.user?.addresses.map((item) => (
              <div
                key={item._id}
                className={`mb-4 p-4 border rounded-lg ${
                  selectedAddress?._id === item._id
                    ? "bg-[#e0e0e0]"
                    : "bg-[#ffffff]"
                }`}
              >
                <AddressCard address={item} />
                <div className="flex flex-col sm:flex-row justify-between mt-2">
                  <Button
                    onClick={() => handleDeliverHere(item)}
                    sx={{
                      color: "white",
                      bgcolor: "#FE5A1D",
                      ":hover": { bgcolor: "#FF7538" },
                      mb: { xs: 1, sm: 0 },
                      width: { xs: "100%", sm: "auto" },
                    }}
                  >
                    Deliver Here
                  </Button>
                  <div className="flex flex-col sm:flex-row mt-1 sm:mt-0">
                    <Button
                      onClick={() => handleEditAddress(item)}
                      sx={{
                        ml: 0,
                        sm: 1,
                        bgcolor: "#f0f0f0",
                        color: "#000000",
                        ":hover": { bgcolor: "#e0e0e0" },
                        mb: { xs: 1, sm: 0 },
                        width: { xs: "100%", sm: "auto" },
                      }}
                    >
                      <EditIcon sx={{ height: "15px", mr: 0.5 }} />
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDeleteAddress(item._id)}
                      sx={{
                        ml: 0,
                        sm: 1,
                        bgcolor: "#E5E4E2",
                        color: "black",
                        ":hover": { bgcolor: "#DA2C43" },
                        width: { xs: "100%", sm: "auto" },
                      }}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="border border-opacity-15 bg-[#F5F5F5] rounded-md shadow-md p-5">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    fullWidth
                    value={addressForm.firstName || ""}
                    onChange={handleFormChange}
                    autoComplete="given-first-name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    value={addressForm.lastName || ""}
                    onChange={handleFormChange}
                    autoComplete="given-last-name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="phoneNumber"
                    name="phoneNumber"
                    label="10-Digit Mobile Number"
                    fullWidth
                    value={addressForm.phoneNumber || ""}
                    onChange={handleFormChange}
                    autoComplete="given-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="streetAddress"
                    name="streetAddress"
                    label="Address (Area and Street)"
                    fullWidth
                    value={addressForm.streetAddress || ""}
                    onChange={handleFormChange}
                    autoComplete="given-address"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="city"
                    name="city"
                    label="City/District/Town"
                    fullWidth
                    value={addressForm.city || ""}
                    onChange={handleFormChange}
                    autoComplete="given-name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="state"
                    name="state"
                    label="State"
                    fullWidth
                    value={addressForm.state || ""}
                    onChange={handleFormChange}
                    autoComplete="given-name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="pincode"
                    name="pincode"
                    label="Pincode"
                    fullWidth
                    value={addressForm.pincode || ""}
                    onChange={handleFormChange}
                    autoComplete="shipping postal-code"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="landmark"
                    name="landmark"
                    label="Landmark (Optional)"
                    fullWidth
                    value={addressForm.landmark || ""}
                    onChange={handleFormChange}
                    autoComplete="given-name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    id="email"
                    name="email"
                    label="Email Address"
                    fullWidth
                    value={addressForm.email || ""}
                    onChange={handleFormChange}
                    autoComplete="given-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      px: "2rem",
                      py: "0.7rem",
                      bgcolor: "#8b7a67",
                      color: "#ffffff",
                      ":hover": { bgcolor: "#aa9d8f" },
                      width: "100%",
                    }}
                    size="large"
                    variant="contained"
                    type="submit"
                  >
                    {isEditing ? "UPDATE ADDRESS" : "SAVE AND DELIVER HERE"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DeliveryAddressForm;
