import React from "react";

const HomeSectionCard = ({ product }) => {
  return (
    <div className="cursor-pointer flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden w-[16rem] hover:scale-105 transition-transform duration-300 ease-in-out">
      <div className="h-[19rem] w-full">
        <img
          className="object-cover h-full w-full"
          src={product.Image}
          // alt={product.title}
        />
      </div>
      <div className="w-full py-2 px-3"></div>
    </div>
  );
};

export default HomeSectionCard;
