import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import "./OrderTracker.css"; // Make sure to import the CSS file

const steps = ["Order Confirmed", "Shipped", "Out for delivery", "Delivered"];

const OrderTracker = ({ activeStep }) => {
  return (
    <div className="w-full">
      <Stepper activeStep={activeStep} alternativeLabel className="stepper">
        {steps.map((label, index) => (
          <Step key={label} className={index < activeStep ? "completed" : ""}>
            <StepLabel
              StepIconProps={{
                sx: {
                  color: "gray", // Default step icon color
                  "&.Mui-active": { color: "#7D7C7C" }, // Active step icon color
                  "&.Mui-completed": { color: "#26a541" }, // Completed step icon color
                },
              }}
              sx={{
                "& .MuiStepLabel-label": {
                  fontFamily: "rubik",
                  color: "#7D7C7C",
                  fontSize: "16px",
                  "&.Mui-active": { color: "#7D7C7C" },
                  "&.Mui-completed": { color: "#26a541" },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default OrderTracker;
