import React from "react";

const CartItems = ({ item }) => {
  return (
    <div className="py-5 px-10 mb-2 shadow-lg border bg-white border-[#4f332c] border-opacity-15 rounded-md">
      <div className="flex items-center ">
        <div className="w-[10rem] h-[10rem] md:w-[12rem] lg:h-[12rem]">
          <img
            className="w-full h-full object-cover object-top cursor-pointer hover:shadow-xl shadow-black}"
            src={item.product?.Image}
            alt=""
          />
        </div>
        <div className="ml-5 space-y-1 ">
          <p className=" font-semibold first-letter:uppercase">
            {item.product?.title}
          </p>
          <p className="opacity-70">Size: {item.size}</p>
          <p className="opacity-70 first-letter:uppercase">
            {item.product?.color}
          </p>
          <div className="flex space-x-3 items-center font-[rubik] text-gray-900 pt-5">
            <p className="text:lg lg:text-3xl">₹{item.discountedPrice}</p>
            <p className="opacity-50 line-through text:lg lg:text-xl">
              ₹{item.price}
            </p>
            <p className="text-green-700 text:lg lg:text-lg">
              {item.product?.discountPercent}% off
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
