import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import HomeSectionCard from "../HomeSectionCard/HomeSectionCard";
import { IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const HomeSectionCarousel = ({ data, SectionName }) => {
  const responsive = {
    0: { items: 1 },
    640: { items: 2 },
    1024: { items: 4 },
    1440: { items: 5 },
  };

  const carousel = useRef(null);

  const items = data.map((item) => (
    <HomeSectionCard key={item.id} product={item} />
  ));

  return (
    <div className="relative shadow-lg rounded-lg overflow-hidden w-full">
      <h1 className="text-3xl font-bold font-[cinzel] px-5 py-4 text-center text-[#705148] bg-white">
        {SectionName}
      </h1>
      <div className="relative px-5 py-3 bg-white">
        <AliceCarousel
          disableButtonsControls
          disableDotsControls
          autoPlay
          autoPlayInterval={3000}
          items={items}
          infinite
          responsive={responsive}
          animationType="slide"
          animationDuration={800}
          touchTracking
          mouseTracking
          paddingLeft={50}
          paddingRight={50}
          ref={carousel}
        />
        <div className="absolute inset-y-0 left-0 flex items-center z-10">
          <IconButton
            onClick={() => carousel.current.slidePrev()}
            className="!bg-gray-400 !text-white !p-2 hover:!bg-gray-700 transition duration-300 ease-in-out"
            size="small"
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </div>
        <div className="absolute inset-y-0 right-0 flex items-center z-10">
          <IconButton
            onClick={() => carousel.current.slideNext()}
            className="!bg-gray-400 !text-white !p-2 hover:!bg-gray-700 transition duration-300 ease-in-out"
            size="small"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default HomeSectionCarousel;
