import React, { useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch } from "react-redux";
import { login } from "../../State/Auth/Action";

const LoginForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(true);

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const userData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    dispatch(login(userData))
      .then((response) => {
        if (response && response.success) {
          handleClose();
        } else {
          console.error("Login failed:", response.error);
        }
      })
      .catch((error) => {
        console.error("Login failed:", error);
      });
  };

  const EndAdornment = ({ visible, setVisible }) => (
    <InputAdornment position="end">
      <IconButton onClick={() => setVisible(!visible)}>
        {visible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
      </IconButton>
    </InputAdornment>
  );

  const EmailAdornment = () => (
    <InputAdornment position="start">
      <EmailIcon />
    </InputAdornment>
  );

  const PasswordAdornment = () => (
    <InputAdornment position="start">
      <LockIcon />
    </InputAdornment>
  );

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="email"
              InputProps={{
                startAdornment: <EmailAdornment />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              fullWidth
              autoComplete="password"
              type={visible ? "password" : "text"}
              InputProps={{
                endAdornment: (
                  <EndAdornment visible={visible} setVisible={setVisible} />
                ),
                startAdornment: <PasswordAdornment />,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className="w-full"
              type="submit"
              variant="contained"
              size="large"
              sx={{
                padding: "1rem 0.5rem",
                background: "#8b7a67",
                ":hover": { background: "#B19470" },
              }}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </form>
      <div className="flex justify-center flex-col items-center">
        <div className="py-3 flex items-center">
          <p>New to MoonMade?</p>
          <Button
            onClick={() => navigate("/register")}
            className="ml-5"
            size="small"
            sx={{ color: "#38419D" }}
          >
            Create an Account
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
